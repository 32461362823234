import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../package.json";
import { FileIcon } from '../src/index';
import { supportedMimeTypes } from '../src/FileIcon';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "file-icon"
    }}>{`File Icon`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <p>{`Icon for mimeTypes`}</p>
    <p>{`Following list is generated from different mimeTypes that we support. This is why there are duplicates.`}</p>
    <ul style={{
      listStyle: "none"
    }}>
    {supportedMimeTypes.map(key => <li key={key}>
                    <FileIcon mimeType={key} mdxType="FileIcon" />
                    <span style={{
          marginLeft: "40px"
        }}>{key}</span>
                </li>)}
    </ul>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={2} __code={'<FileIcon mimeType={\'application/x-adobe-indesign\'} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      FileIcon,
      supportedMimeTypes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <FileIcon mimeType={"application/x-adobe-indesign"} mdxType="FileIcon" />
    </Playground>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={FileIcon} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      